export const sortImages = (images) => {
  // Check length before beginning sort
  if (images.length === 0) {
    throw new Error('No images provided.');
  }

  try {
    //BIN PACKING
    let nestedArray = [
      [0, 0],
      [0, 0]
    ];

    //Array that we update and return
    const sortedImages = [];

    let aspectRatio = 0;

    //function that updates the values of the nestedArray from 0 to 1
    const updateValue = (outerIndex, innerIndex) => {
      const newArray = [...nestedArray]; // Create a copy of the outer array
      const innerArray = [...newArray[outerIndex]]; // Create a copy of the inner array
      innerArray[innerIndex] = 1; // Modify the desired value within the inner array
      newArray[outerIndex] = innerArray; // Replace the modified inner array within the outer array copy

      nestedArray = newArray;
    };

    //function that pushes the image #i with the loading class
    //to the sortedImages array
    const pushLoadingClass = () => {
      sortedImages.push({
        class: 'loading'
      });
    };

    //function that pushes the image #i with the single class
    //to the sortedImages array
    const pushSingleClass = (i) => {
      sortedImages.push({
        class: 'single',
        height: images[i].height,
        src: images[i].url,
        title: images[i].title,
        width: images[i].width
      });
    };

    //function that pushes the image #i with the full width class
    //to the sortedImages array
    const pushFullWidthClass = (i) => {
      sortedImages.push({
        class: 'fullWidth',
        height: images[i].height,
        src: images[i].url,
        title: images[i].title,
        width: images[i].width
      });
    };

    //function that pushes the image #i with the full height class
    //to the sortedImages array
    const pushFullHeightClass = (i) => {
      sortedImages.push({
        class: 'fullHeight',
        height: images[i].height,
        src: images[i].url,
        title: images[i].title,
        width: images[i].width
      });
    };

    for (var i = 0; i < images.length; i++) {
      const rowOne = nestedArray[nestedArray.length - 2];
      const rowTwo = nestedArray[nestedArray.length - 1];

      if (images[i].width == undefined || images[i].height == undefined) {
        aspectRatio = undefined;
      } else {
        aspectRatio = images[i].height / images[i].width;
      }

      //if first row & rowTwo Column one is empty & not a portrait image
      //then make the first row full width
      if (images.length - 1 === i && rowTwo[1] === 0 && aspectRatio < 1) {
        if (rowTwo[0] === 0 && rowTwo[1] === 0) {
          updateValue(nestedArray.length - 2, 0);
          updateValue(nestedArray.length - 2, 1);

          pushFullWidthClass(i);

          continue;
        }
      }

      //If a portrait image
      if (aspectRatio > 1) {
        //If row 1 column 1 is empty & if row 2 column 1 is empty
        //return a portrait image
        if (rowOne[0] === 0 && rowTwo[0] === 0) {
          updateValue(nestedArray.length - 1, 0);
          updateValue(nestedArray.length - 2, 0);
          pushFullHeightClass(i);

          continue;
        }

        //If row 1 column 2 is empty & if row 2 column 2 is empty
        //return a portrait image
        if (rowOne[1] === 0 && rowTwo[1] === 0) {
          updateValue(nestedArray.length - 1, 1);
          updateValue(nestedArray.length - 2, 1);
          pushFullHeightClass(i);

          continue;
        }

        //If row 2 column 2 is empty
        //return a portrait image
        if (rowTwo[1] === 0) {
          updateValue(nestedArray.length - 1, 1);
          nestedArray.push([0, 1]);
          pushFullHeightClass(i);

          continue;
        }

        //If row 2 column 1 is full & row 2 column 2 is full
        //push 2 new rows with first column full and second column empty to array
        //return a portrait image
        if (rowTwo[0] === 1 && rowTwo[1] === 1) {
          nestedArray.push([1, 0], [1, 0]);
          pushFullHeightClass(i);

          continue;
        }

        //If row 2 column 1 is empty & row 2 column 2 is full
        //push 1 new row with column 1 full and column 2 empty
        //return a portrait image
        if (rowTwo[0] === 0 && rowTwo[1] === 1) {
          updateValue(nestedArray.length - 1, 1);
          nestedArray.push([1, 0]);
          pushFullHeightClass(i);

          continue;
        }
      }

      //If aspect ratio is undefined
      if (aspectRatio == undefined) {
        //if row 1 column 1 is empty
        //return a loading column
        if (rowOne[0] === 0) {
          updateValue(nestedArray.length - 2, 0);
          pushLoadingClass(i);

          continue;
        }

        //if row 2 column 1 is empty
        //return a loading column
        if (rowTwo[0] === 0) {
          updateValue(nestedArray.length - 1, 0);
          pushLoadingClass(i);

          continue;
        }

        //if row 1 column 2 is empty
        //return a loading column
        if (rowOne[1] === 0) {
          updateValue(nestedArray.length - 2, 1);
          pushLoadingClass(i);

          continue;
        }

        //if row 2 column 2 is empty
        //return a loading column
        if (rowTwo[1] === 0) {
          updateValue(nestedArray.length - 1, 1);
          pushLoadingClass(i);

          continue;
        }

        //if row 2 column 1 is full and row 2 column 2 is full
        //push a new row with column 1 full and column 2 empty
        //return a loading column
        if (rowTwo[0] === 1 && rowTwo[1] === 1) {
          nestedArray.push([1, 0]);
          pushLoadingClass(i);

          continue;
        }
      }

      //grab the last two images from the sorted images array
      const slicedArray = sortedImages.slice(sortedImages.length - 2);
      //check if they are full width images
      const filteredArray = slicedArray.filter((value) => value.class === 'fullWidth');

      //if there is one or more than one full width image in the filtered array
      //and the current image is not the last image
      if (filteredArray.length >= 1 && i !== images.length - 1) {
        //if row 1 column 1 is empty
        //return a single column image
        if (rowOne[0] === 0) {
          updateValue(nestedArray.length - 2, 0);
          pushSingleClass(i);

          continue;
        }

        //if row 2 column 1 is empty
        //return a single column image
        if (rowTwo[0] === 0) {
          updateValue(nestedArray.length - 1, 0);
          pushSingleClass(i);

          continue;
        }

        //if row 1 column 2 is empty
        //return a single column image
        if (rowOne[1] === 0) {
          updateValue(nestedArray.length - 2, 1);
          pushSingleClass(i);

          continue;
        }

        //if row 2 column 2 is empty
        //return a single column image
        if (rowTwo[1] === 0) {
          updateValue(nestedArray.length - 1, 1);
          pushSingleClass(i);

          continue;
        }

        //if row 2 column 1 is full and row 2 column 2 is full
        //push a new row with column 1 full and column 2 empty
        //return a single column image
        if (rowTwo[0] === 1 && rowTwo[1] === 1) {
          nestedArray.push([1, 0]);
          pushSingleClass(i);

          continue;
        }

        //if there is less than one full width image or the current image is the last image
      } else {
        //if row 1 column 1 is empty and row one column 2 is empty
        //return a double column image
        if (rowOne[0] === 0 && rowOne[1] === 0) {
          updateValue(nestedArray.length - 2, 0);
          updateValue(nestedArray.length - 2, 1);
          pushFullWidthClass(i);

          continue;
        }

        //if row 2 column 1 is empty and row 2 column 2 is empty
        //return a double column image
        if (rowTwo[0] === 0 && rowTwo[1] === 0) {
          updateValue(nestedArray.length - 1, 0);
          updateValue(nestedArray.length - 1, 1);
          pushFullWidthClass(i);

          continue;
        }

        //if row 2 column 1 is empty
        //return a single column image
        if (rowTwo[0] === 0) {
          updateValue(nestedArray.length - 1, 0);
          pushSingleClass(i);

          continue;
        }

        //if row 1 column 2 is empty
        //return a single column image
        if (rowOne[1] === 0) {
          updateValue(nestedArray.length - 2, 1);
          pushSingleClass(i);

          continue;
        }

        //if row 2 column 2 is empty
        //return a single column image
        if (rowTwo[1] === 0) {
          updateValue(nestedArray.length - 1, 1);
          pushSingleClass(i);

          continue;
        }

        //if row 2 column 1 is full and row 2 column 2 is full
        //push a new full row
        //return a double column image
        if (rowTwo[0] === 1 && rowTwo[1] === 1) {
          nestedArray.push([1, 1]);
          pushFullWidthClass(i);

          continue;
        }
      }
    }
    return sortedImages;
  } catch (error) {
    // Handle the error and throw a response
    throw new Error('An error occurred while sorting the photo gallery images.');
  }
};
