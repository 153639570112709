/* eslint-disable no-empty */
import { gql } from '@apollo/client';
import { rest, graphql } from '@lib/api';
import { LISTING_ATTRIBUTES } from '@lib/fragments';
import { toArray } from '@lib/utils';

export const LISTING_QUERY = gql`
  ${LISTING_ATTRIBUTES}
  query GetListing($id: Int!) {
    listingById(listingId: $id) {
      ...Listing
    }
  }
`;

export const LISTINGS_QUERY = gql`
  ${LISTING_ATTRIBUTES}
  query GetListings($ids: [Int!]) {
    listingsByIds(listingIds: $ids) {
      ...Listing
    }
  }
`;

export default class Listing {
  /**
   * Static method intended to hydrate objects in arr
   * with attributes defined in LISTINGS_QUERY
   * for those objects that have a `listingId` key
   *
   * Usage:
   *i
   *  const listings = [{ listingId: 123 }]
   *  Listing.hydrate(listings)
   *
   *  Returns... [{listingId: 123, address: '...', city: '...', etc}]
   *
   * @param {Array}
   * @returns {Array}
   */
  static async hydrate(arr) {
    const ids = [...new Set(arr.map((obj) => obj.listingId))];
    const { listingsByIds: listings } = await graphql(LISTINGS_QUERY, { ids: ids });

    return arr.map((obj) => ({
      ...obj,
      ...toArray(listings).find((l) => l.listingId === obj.listingId)
    }));
  }

  /**
   * @param {Integer} Listing ID
   */
  constructor(id) {
    this.id = id;
  }

  /**
   * Query all details for this listing
   * @returns {Object}
   */
  async details() {
    try {
      const { listingById } = await graphql(LISTING_QUERY, { id: this.id });
      return listingById;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  /**
   * Increments and returns the value for a listing's totalViews
   * @returns {Integer}
   */
  async stats() {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
      const {
        data: { totalViews }
      } = await rest(`/listings/${this.id}/stats`, {
        method: 'patch'
      });
      return totalViews;
    }
  }

  async interest(years) {
    const {
      data: { currentRate }
    } = await rest(
      `/interest-rates/${years}yf`,
      {
        method: 'get'
      },
      true
    );

    return currentRate;
  }

  /**
   * Returns up to three similar listing objects
   * @returns {Array}
   */
  async getSimilarListings(arr) {
    try {
      if (arr.length > 0) {
        const { listingsByIds: listings } = await graphql(LISTINGS_QUERY, { ids: arr });
        return listings;
      } else {
        return arr;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  /**
   * Takes in a url string and returns a cannonicalized version
   * @param {String}
   * @returns {String}
   */
  static canonicalize(string) {
    return string.replace(/^[, ]+|[, ]+$|[, ]+/g, '-');
  }
}
