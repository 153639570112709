import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'photoswipe/dist/photoswipe.css';
import { Gallery } from 'react-photoswipe-gallery';
import { sortImages } from '@lib/sort-images';

import GalleryImage from './gallery-image';
import Spinner from '@components/spinner';
import Button from '@components/button';

import { grid, spinner, full_grid } from './styles.module.scss';
import classNames from 'classnames';

const PhotoGallery = ({ images, triggerFirstSort, fullWidthGrid }) => {
  const [imageSortFailed, setImageSortFailed] = useState(false);
  const [sortedImagesState, setSortedImagesState] = useState('loading');

  useEffect(() => {
    const fetchData = async () => {
      if (triggerFirstSort && sortedImagesState === 'loading') {
        sortAndSetImages();
      }
    };

    fetchData();
  }, [triggerFirstSort]);

  const sortAndSetImages = async () => {
    try {
      // Fetch image dimensions (if no dimensions returned) and sort images
      setImageSortFailed(false);
      let imageSizes = images;

      const hasMissingSize = images.some((img) => !img.width || !img.height);

      if (hasMissingSize) {
        imageSizes = await fetchImageDimensions(images);
      }

      const sortedImages = sortImages(imageSizes);
      setSortedImagesState(sortedImages);
    } catch (error) {
      setImageSortFailed(true);
      setSortedImagesState('loading');
    }
  };

  const fetchImageDimensions = async (images) => {
    try {
      const response = await axios.post('/api/getImageSizes', { images });
      return response.data;
    } catch (error) {
      console.error('Error fetching image dimensions:', error);
    }
  };

  return (
    <>
      <div className={fullWidthGrid ? classNames(grid, full_grid) : grid}>
        {sortedImagesState !== 'loading' && (
          <Gallery withCaption={true}>
            {sortedImagesState.map((image, index) => (
              <GalleryImage key={index} image={image} index={index} />
            ))}
          </Gallery>
        )}
      </div>
      {sortedImagesState === 'loading' && !imageSortFailed && (
        <div className={spinner}>
          <Spinner />
        </div>
      )}
      {imageSortFailed && (
        <div className={spinner}>
          <Button
            type="button"
            iconType="actions"
            icon="rotate"
            label={`Retry Load Images`}
            onClick={() => sortAndSetImages()}
          />
        </div>
      )}
    </>
  );
};

PhotoGallery.propTypes = {
  /**
   * Specifies if using the full width grid
   */
  fullWidthGrid: PropTypes.bool,

  /**
   * Specifies the images that will be sorted based upon their height and width
   */
  images: PropTypes.arrayOf(
    PropTypes.shape({
      height: PropTypes.number,
      title: PropTypes.string,
      url: PropTypes.string,
      width: PropTypes.number
    })
  ),

  /**
   * Specifies if the sorting of images can begin
   */
  triggerFirstSort: PropTypes.bool
};

PhotoGallery.defaultProps = {
  fullWidthGrid: true
};

export default PhotoGallery;
