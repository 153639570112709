import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import NextImage from 'next/image';
import { Item } from 'react-photoswipe-gallery';
import { grid_item, full_height, full_width, skeleton } from './styles.module.scss';

const GalleryImage = ({ image, index }) => {
  const [isLoading, setIsLoading] = useState(true);
  const itemRef = useRef(null);

  const skeletonLoader = <div className={skeleton}></div>;

  let className;

  switch (image.class) {
    case 'fullHeight':
      className = classNames(grid_item, full_height);
      break;
    case 'single':
    case 'loading':
      className = grid_item;
      break;
    case 'fullWidth':
      className = classNames(grid_item, full_width);
      break;
    default:
      break;
  }

  const handleKeyDown = (e, open) => {
    if (e.key === 'Enter') {
      open();
    }
  };

  useEffect(() => {
    const handleIntersection = (entries) => {
      if (entries[0].isIntersecting && isLoading) {
        setIsLoading(false);
      }
    };

    if (itemRef.current) {
      const observer = new IntersectionObserver(handleIntersection, {
        rootMargin: '500px' // This sets the threshold to 500px before the element comes into view
      });

      observer.observe(itemRef.current);

      return () => {
        if (itemRef.current) {
          observer.unobserve(itemRef.current);
        }
      };
    }
  }, [isLoading]);

  return (
    <>
      {image.class === 'loading' ? (
        <>{skeletonLoader}</>
      ) : (
        <Item
          original={image.src}
          caption={image.title}
          thumbnail={image.src}
          height={image.height}
          width={image.width}
        >
          {({ ref, open }) => (
            <div
              role="button"
              tabIndex="0"
              onKeyDown={(e) => handleKeyDown(e, open)}
              ref={(node) => {
                ref.current = node;
                itemRef.current = node;
              }}
              onClick={open}
              key={index}
              className={className}
            >
              {isLoading && skeletonLoader}
              <NextImage
                alt={image.title}
                height={image.height}
                layout="responsive"
                objectFit="cover"
                priority={index < 6}
                unoptimized={image.src.includes('sibcycline') || image.src.includes('google')}
                src={image.src}
                width={image.width}
              />
            </div>
          )}
        </Item>
      )}
    </>
  );
};

GalleryImage.propTypes = {
  image: PropTypes.shape({
    class: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.number
  }),
  index: PropTypes.number
};

export default GalleryImage;
